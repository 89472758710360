import React, { useEffect } from "react";
import { json } from "@remix-run/node";
import login1 from "../../assets/login1.jpg";
import login2 from "../../assets/login2.jpg";
import login3 from "../../assets/login3.jpg";
import login4 from "../../assets/login4.jpg";
import login5 from "../../assets/login5.jpg";
import kptnCookLogo from "~/assets/Logo_KptnCook_red.png";

import LoginForm from "../../features/Login/components/LoginForm";
import { login } from "../../server/auth.server";
import { useActionData } from "@remix-run/react";
import { setUserValuesInLS } from "../../features/Login/utils";

const Login = () => {
  const actionData = useActionData();

  useEffect(() => {
    if (window && actionData) {
      setUserValuesInLS(actionData.user);
    }
  }, [typeof window, actionData]);

  const images = [login1, login2, login3, login4, login5];
  const randomIndex = Math.floor(Math.random() * images.length);
  const randomImage = images[randomIndex];

  return (
    <main className="grid h-screen grid-cols-1 md:grid-cols-[1fr,2fr]">
      <div className="grid place-items-center">
        <div className="flex max-w-[80%] flex-col gap-2.5">
          <Logo src={kptnCookLogo} />
          <TagLine />
          <div>
            <LoginForm />
          </div>
          {actionData && <ErrorMsg msg={actionData.msg} />}
        </div>
      </div>
      <Image src={randomImage} />
    </main>
  );
};

const Logo = ({ src }) => (
  <div className="grid place-items-center">
    <img className="w-[190px]" src={src} alt="KptnCook is the best!" />
  </div>
);

const TagLine = () => (
  <p className="mx-auto w-full text-center font-Alianza700">
    Empowers you to make the best food choices
  </p>
);

const ErrorMsg = ({ msg }) => (
  <span className="text-center text-primary-100">{msg}</span>
);

const Image = ({ src }) => (
  <div className="sm: hidden md:block">
    <img className="h-full w-full object-cover " src={src} alt="Yummy food" />
  </div>
);

export const action = async ({ request }) => {
  const formData = await request.formData();
  const url = new URL(request.url);

  const redirect = url.searchParams.get("redirect");
  const email = formData.get("email");
  const password = formData.get("password");

  try {
    return await login(email, password, redirect);
  } catch (e) {
    console.info(e);
    return json({ msg: "Incorrect Email and / or Password" });
  }
};

export default Login;
