import { useState } from "react";
import { z } from "zod";
import Form from "../../../utils/form";
import { useNavigation } from "@remix-run/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginForm = () => {
  const transition = useNavigation();
  const isSubmitting = Boolean(transition.state === "submitting");

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const TogglePasswordIcon = () => (
    <span onClick={togglePassword} className="opacity-70">
      {passwordType === "password" ? <Visibility /> : <VisibilityOff />}
    </span>
  );

  return (
    <Form schema={LoginSchema} method="post">
      {({ Field, Errors, Button }) => {
        return (
          <>
            <Field name="email" className="col-span-12" />
            <Field
              name="password"
              type={passwordType}
              className="relative col-span-12"
              icon={<TogglePasswordIcon />}
            />
            <Field name="redirect" hidden readOnly />
            <Errors />
            <div className="col-span-12 grid">
              <Button
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                text="Log in"
              />
            </div>
          </>
        );
      }}
    </Form>
  );
};

export const LoginSchema = z.object({
  email: z.string().email("Not a valid mail address"),
  password: z.string().min(4),
});

export default LoginForm;
